/* global PRODUCTION VUE */

import { metrics } from './theme/scripts/ferg-metrics.js';
import { loadApp } from './vue-app-loader.js';

console.log(`${PRODUCTION ? 'Production Environment' : 'Dev Environment'}`);

if (PRODUCTION) {
	// Production-specific settings go here.
	// console.log = () => {};
} else {
	metrics();
}

document.addEventListener('DOMContentLoaded', function() {
	if (!PRODUCTION) console.log('DOMContentLoaded');

	initVue();
	handleHamburgers();
	handleDropdowns();
});

function handleHamburgers() {
	// Get all "navbar-burger" elements
	const navbarBurgers = document.querySelectorAll('.navbar-burger'); // Check if there are any navbar burgers

	if (navbarBurgers.length > 0) {
		// Add a click event on each of them
		navbarBurgers.forEach(function(el) {
			el.addEventListener('click', function() {
				// Get the target from the "data-target" attribute
				const target = el.dataset.target;
				const $target = document.getElementById(target); // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"

				el.classList.toggle('is-active');
				$target.classList.toggle('is-active');
			});
		});
	}
}

function handleDropdowns() {
	const dropdowns = document.querySelectorAll('.navbar-dropdown'); // Check if there are any navbar burgers

	if (dropdowns.length > 0) {
		dropdowns.forEach(function(el) {
			const navbarItem = el.parentElement;
			const toggle = navbarItem.querySelector('.navbar-link');

			toggle.addEventListener('click', function() {
				navbarItem.classList.toggle('is-active');

				document.addEventListener('click', function _listener(e) {
					if (!navbarItem.contains(e.target)) {
						navbarItem.classList.toggle('is-active');
						document.removeEventListener('click', _listener);
					}
				});
			});
		});
	}
}

async function initVue() {
    let appElements = document.getElementsByClassName('vue-app');

    if (appElements.length > 0) {
        const {loadApp} = await import('./vue-app-loader');

        for (let app of appElements) {
            if (app.id) {
                loadApp(app.id);
            }
        }
    }
}
