import Vue from 'vue';
// import store from './vue-apps/store';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css'
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Buefy);
// window.globalVue = new Vue({ store });

export function loadApp(appId) {
    console.log('loading app: ', appId);
    if (typeof appId != 'string' || !appId) throw new Error('no Vue app specified');

    import(`./vue/${appId}`).then(module => {
        // The component may either be exported as App or default. Either way works.
        const App = !!module && module.App || module.default;
        if (!App) {
            throw new Error('Vue app failed to load');
        }

        // // App module can specify init function for setting up things like Vue plugins (needs exported if applicable)
        // if (typeof module.init == 'function') {
        //     module.init();
        // }
        // Vue.prototype.$eventBus = new Vue(); // Global event bus

        const appEl = document.getElementById(appId);
        let props;

        // inject props into component if there's a `data-props="{}"` on the app element
        if ('props' in appEl.dataset) {
            const _props = safeishEval(appEl.dataset.props);

            if (typeof _props == 'object') {
                props = _props;
                console.log(appId, 'app has props', props);
            }
        }


        new Vue({
            el: appEl,
			// store,
            name: appId + 'Root',
            render: h => h(App, {props})
        });

        // const app = createApp({
        //     name: appId + 'Root',
        //     render: () => h(App, {props})
        // });

        // app.mount(appEl);
    });
}
/**
 * Adapted and simplified from Alpine's saferEval utility: https://github.com/alpinejs/alpine/blob/master/src/utils.js#L88
 * @param {string} expression
 * @returns {Object}
 */
function safeishEval(expression) {
    return (new Function([], `var __result = ${expression}; return __result`))()
}
